<template>
    <div>
        <button
            class="btn btn-primary btn-agregar-participante "
            @click="openModal = true"
        >
            Agregar Cliente
        </button>
        <b-modal
            @ok.prevent="saveDis"
            title="Agregar cliente"
            v-model="openModal"
            size="lg"
        >
            <validation-observer tag="b-row" ref="form">
                <b-col cols="6">
                    <validation-provider
                        rules="required"
                        v-slot="{ errors, valid }"
                    >
                        <b-form-group
                            label="Nombre del cliente"
                            label-for="name"
                        >
                            <b-form-input
                                placeholder="Nombre del cliente"
                                v-model="name"
                                :state="errors[0] ? false : valid ? true : null"
                            />
                            <span style="color: red">{{ errors[0] }}</span>
                        </b-form-group>
                    </validation-provider>
                </b-col>

                <b-col cols="6">
                    <validation-provider
                        rules="required"
                        v-slot="{ errors, valid }"
                    >
                        <b-form-group
                            label="Apellido del cliente"
                            label-for="lastname"
                        >
                            <b-form-input
                                placeholder="Apellido del cliente"
                                v-model="lastname"
                                :state="errors[0] ? false : valid ? true : null"
                            />
                            <span style="color: red">{{ errors[0] }}</span>
                        </b-form-group>
                    </validation-provider>
                </b-col>

                <b-col cols="6">
                    <b-form-group
                        label="Teléfono del cliente"
                        label-for="phone"
                        v-mask="'###-###-###'"
                    >
                        <b-form-input
                            placeholder="Teléfono del cliente"
                            v-model="phone"
                        />
                    </b-form-group>
                </b-col>

                <b-col cols="6">
                    <b-form-group label="Correo del cliente" label-for="email">
                        <b-form-input
                            placeholder="Correo del cliente"
                            v-model="email"
                        />
                    </b-form-group>
                </b-col>

                <b-col cols="6">
                    <b-form-group
                        label="Dirección del cliente"
                        label-for="address"
                    >
                        <b-form-input
                            placeholder="Dirección del cliente"
                            v-model="address"
                        />
                    </b-form-group>
                </b-col>
                <!-- TODO: CAMBIAR LOS CAMPOS POR EL TIPO SI ES PERSONAL NATURAL O EMPRESA -->
                <b-col cols="6">
                    <b-form-group
                        label="Tipo de persona"
                        label-for="type_person"
                    >
                        <v-select
                            v-model="type_person"
                            :options="typePersonArray"
                            :clearable="false"
                            label="label"
                            :reduce="option => option.id"
                            transition=""
                            placeholder="Elija tipo de persona"
                        />
                    </b-form-group>
                </b-col>

                <b-col cols="6">
                    <b-form-group label="Tipo de documento" label-for="doc">
                        <v-select
                            v-model="document_type"
                            :options="documentTypeArray"
                            :clearable="false"
                            label="label"
                            :reduce="option => option.id"
                            transition=""
                            placeholder="Elija tipo de documento"
                        />
                    </b-form-group>
                </b-col>

                <b-col cols="6">
                    <b-form-group
                        label="N° de documento"
                        label-for="document_number"
                    >
                        <b-form-input
                            placeholder="N° de documento"
                            v-model="document_number"
                        />
                    </b-form-group>
                </b-col>
            </validation-observer>
        </b-modal>
    </div>
</template>

<script>
import { saveClient } from "../clients.service";
import { mapState } from "vuex";
import vSelect from "vue-select";

export default {
    name: "AddClient",
    components: { vSelect },
    data() {
        return {
            openModal: false,
            name: "",
            lastname: "",
            phone: "",
            email: "",
            address: "",
            type_status: "",
            document_type: "",
            document_number: "",
            type_person: "",

            documentTypeArray: [
                {
                    id: "DNI",
                    label: "DNI"
                },
                {
                    id: "RUC",
                    label: "RUC"
                },
                {
                    id: "PASAPORTE",
                    label: "PASAPORTE"
                },
                {
                    id: "CARNET DE EXTRANJERIA",
                    label: "CARNET DE EXTRANJERIA"
                },
                {
                    id: "OTRO",
                    label: "OTRO"
                }
            ],

            typePersonArray: [
                {
                    id: "PERSONA",
                    label: "PERSONA"
                },
                {
                    id: "EMPRESA",
                    label: "EMPRESA"
                }
            ],

            errorText: false,
            valid: false
        };
    },
    computed: {
        ...mapState("auth", ["currentUser"])
    },
    methods: {
        async saveDis() {
            const success = await this.$refs.form.validate();
            if (success) {
                const { ok, msg } = await saveClient({
                    nameIn: this.name,
                    lastnameIn: this.lastname,
                    phoneIn: this.phone,
                    emailIn: this.email,
                    addressIn: this.address,
                    typePersonIn: this.type_person,
                    typeDocumentIn: this.document_type,
                    document: this.document_number,
                    userIdIn: this.currentUser.user_id
                });
                if (ok) {
                    await this.showSuccessToast(
                        msg,
                        "success",
                        "top-left",
                        "Enfermedad creada",
                        "CheckIcon"
                    );
                    this.openModal = false;
                    this.name = null;
                    this.lastname = null;
                    this.phone = null;
                    this.email = null;
                    this.address = null;
                    this.type_person = null;
                    this.document_type = null;
                    this.document_number = null;
                } else {
                    await this.showSuccessToast(
                        msg,
                        "warning    ",
                        "top-left",
                        "Información",
                        "InfoIcon"
                    );
                }
                this.$emit("refresh");
            } else {
            }
        }
    },
    watch: {
        valid() {
            if (this.name.length > 3) {
                this.valid = true;
            } else {
                this.valid - false;
            }
        }
    }
};
</script>

<style lang="scss" scope>
.btn-agregar-participante {
    width: 150px !important;
    font-size: 10px;
    @media screen and (min-width: 600px) {
        width: 200px !important;
        font-size: 14px;
    }
}
.error-input {
    margin-bottom: 0;
    color: red;
    font-size: 12px;
    margin-top: 2px;
}
</style>
