import axios from "@/axios";

const url = '/api/clients/';

export const getClients = async () => {
    const { data } = await axios.get(`${url}get-clients`);
    return data;
};
export const saveOuputSpecimenClient = async (body) => {
    const { data } = await axios.post(
        `${url}save-ouput-specimen-client`,
        body
    );
    return data;
};

export const saveClient = async (body) => {
    const { data } = await axios.post(`${url}save-client`, body);
    return data;
};

export const getClientsToStatus = async (body) => {
    const { data } = await axios.post(
        `${url}get-clients-to-status`,
        body
    );
    return data;
};

export const updateStatusByIdClient = async (body) => {
    const { data } = await axios.put(`${url}update-status-by-id`, body);
    return data;
};

export const deleteClientById = async (body) => {
    const { data } = await axios.put(`${url}delete-by-id`, body);
    return data;
};

export const updateClientById = async (body) => {
    const { data } = await axios.put(`${url}update-by-id`, body);
    return data;
};